<template>
  <div class="card">
    <div>
      <validation-observer ref="addUser">
        <b-form>
          <b-row>
            <b-col class="marin-20">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <label>First Name</label>
                  <br>
                  <b-form-input
                    v-model="firstname"
                    id="v-first-name"
                    class="mr-bottom"
                    placeholder="First name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <br>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <label>Last Name</label>
                  <b-form-input
                    v-model="lastname"
                    id="v-first-name"
                    class="mr-bottom"
                    placeholder="Last name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <br>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <label>Email</label>
                  <b-form-input
                    v-model="uemail"
                    id="v-first-name"
                    class="mr-bottom"
                    placeholder="Email name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <br>
                </validation-provider>
                <label>Designation</label>
                <b-form-input
                  class="mr-bottom"
                  rows="1"
                  v-model="designation"
                  placeholder="Designation"
                />
              </b-form-group>
              <!-- <template> -->
              <div>
                <b-form-group label="Roles">
                  <b-form-checkbox-group
                    v-for="(role, index) in allRoles"
                    :id="role._id"
                    name="flavour-2"
                    class="demo-inline-spacing inline-flex"
                    v-model="selected"
                    :key="index"
                  >
                    <b-form-checkbox :value="role.name">
                      {{ role.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col class="marin-20" cols="12">
              <b-button
                @click="addUserData()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="reset()"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, watchEffect } from "vue";
import {
  BFormTextarea,
  BPagination,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
  data: () => ({
    firstname: "",
    lastname: "",
    uemail: "",
    designation: "",
    roles: [],
    allRoles: [],
    tenantId: "",
    selected: [],
    optionsVal: [],
    email,
    required,
  }),
  components: {
    BFormTextarea,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {},
  watch: {},
  mounted() {
    this.tenantId = this.$store.state.auth.tenantid;
    this.getRole();
  },

  computed: {},
  methods: {
    reset() {
      this.firstname = "";
      this.lastname = "";
      this.email = "";
      this.designation = "";
      this.selected = "";
    },
    getRole() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/roles`,
      };
      this.$http(options)
        .then((res) => {
          this.allRoles = res.data.data;
          this.allRoles.forEach((element) => {
            element.checked = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addUserData() {
       this.$refs.addUser.validate().then(valid => {
        if (!valid) {
          // do stuff if not valid.
        }
        else{
            const body = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.uemail,
        designation: this.designation,
        roles: this.selected,
      };

      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/users`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          this.$router.push({ name: "usermanagement" });
        })
        .catch((err) => {
          console.log(err);
        });
        }
      }); 
      
        
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.width-20 {
  width: 20%;
}
.full-width {
  width: 100%;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.mr-right {
  margin-right: 10px;
}
.width-40 {
  width: 40%;
}
.mr-bottom {
  margin-bottom: 10px;
}
.marin-20 {
  margin: 20px;
}
</style>
